@tailwind base;
@tailwind components;
@tailwind utilities;

$primaryColor:#1b293c;
$secondaryColor: #D46119;

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-size: 13px;
}

body{
    font-size: 18px;
	background-color: #f1f1f1;
}

.item{
    margin: 10px;
    text-align: center;
	background-color: #fff;
	border:1px solid rgb(170, 170, 170);
	font-size: large;
	padding: 20px;
}

.item h1{
	font-weight: bold;
}

.item img{
    border-radius: 10px;
}

.page-header {
	background-image: url("../../images/pamovations-light.png");
	background-position: top;
	background-size: cover;
	background-repeat: no-repeat;
	background-color: #090909;
	background-attachment: fixed;
	color: #fff;
	min-height: 760px;
}

// .page-header::after {
// 	background-image: url("../../images/pamovations-light.png");
// 	background-position: top;
// 	background-size: cover;
// 	background-repeat: no-repeat;
// 	background-color: #040404;
// 	background-attachment: fixed;
// 	color: #fff;
// 	min-height: 760px;
// }

.menu{
    background-color: $primaryColor;
}

.menu-scrolled{
    background-color: $primaryColor;
    position: fixed;
}

.menu ul li{
    color:#fff;
	font-weight: bold;
}

.box-header h1{
	color:$primaryColor;
}

.item h1{
	color:$primaryColor;
}

@media (max-width: 768px) {
	.page-header {
		background-image: url("../../images/pamovations-light.png");
		background-position: top;
		background-size: cover;
		background-repeat: no-repeat;
		background-color: #090909;
		background-attachment: fixed;
		color: #fff;
		min-height: 400px;
	}
}
@media (max-width: 768px) {
	.menu .logo{
		display: none;
	}

	.menu{
		display: flex;
		justify-content: center;
		align-content: center;
	}

}
@media (min-width: 768px) {
	.menu{
		display: flex;
		justify-content: space-between;
		align-content: flex-start;
	}
}
